import React from "react"
import "./styles.scss"

/**
 * This turns the public facing url (https://www.youtube.com/watch?v=DuECj8Eon3A)
 *  into a properly formatted url for embedding into an iframe (https://www.youtube.com/embed/DuECj8Eon3A)
 */
const createEmbeddingURL = url => {
  const splitUrl = url ? url.split("watch?v=") : null
  const splitId = splitUrl && splitUrl.length > 1 ? splitUrl[1].split('&') : null
  let result = ''
  if (splitId && splitId.length > 0) {
    result = `https://www.youtube.com/embed/${splitId[0]}?`
  }
  result += splitId.slice(1).join('&')
  return result
}

const YouTubeVideo = ({ watchURL, title, ...props }) => (
  <div className="video-wrapper">
    <iframe
      data-testid="iframe"
      src={createEmbeddingURL(watchURL)}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      {...props}
    />
  </div>
)

export default YouTubeVideo
