import React from "react"
import "./styles.scss"

const AgendaEvent = ({ event, onSelectAgendaEvent }) => (
  <div>
    <span
      tabindex="0"
      role="button"
      className="agendaText"
      onKeyDown={ev => {
        if (ev.key === " " || ev.key === "Enter" || ev.key === "Spacebar") {
          onSelectAgendaEvent(event)
        }
      }}
      onClick={() => onSelectAgendaEvent(event)}
    >
      {event.name}
    </span>
  </div>
)

export default AgendaEvent
