import React from "react"
import "./styles.scss"
import YouTubeVideo from "../YouTubeVideo"
import { isInFuture, formatTime } from "../../utils/dates"

const Event = ({ event }) => {
  let video
  let shouldDisplayVideo = event.video && !isInFuture(event.video.publish_date, event.video.publish_time)
  if (shouldDisplayVideo) {
    video = (<div className="col-12 mt-3">
      <YouTubeVideo watchURL={event.video.video_url} title={event.name} />
    </div>)
  }
  else {
    video = ""
  }

  return (
    <div className="row">
      {video}
      <div className="col-12 details mt-3">
        <h3 data-testid="name" className={shouldDisplayVideo ? "px-3 mb-1 pb-1 border-bottom" : "d-none"}>
          {event.name}
        </h3>
        <div className="px-3">
          <p data-testid="description">
            {event.description}
          </p>
          <p data-testid="date">Date: {event.format_date}</p>
          <p data-testid="time">Time: {formatTime(event.start_time)} - {formatTime(event.end_time)}</p>
          <p data-testid="performers-label" className="mb-0">
            Performers:
          </p>
          <ul data-testid="performers-list">
            {event.performers &&
              event.performers.map((p, i) => {
                return (
                  <li className="my-2" key={i}>{` ${p.name}${i > 0 ? "," : ""
                    }`}</li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Event
