import React, { useState } from "react"
import { graphql } from "gatsby"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"

import Layout from "../components/Layout"
// import Seo from "../components/Seo"
import Event from "../components/Event"
import AgendaEvent from "../components/AgendaEvent"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Text from "../components/Text"
import { formatTime } from "../utils/dates"

const localizer = momentLocalizer(moment)

const EventsPage = ({ data }) => {
  const [show, setShow] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState({})
  let events = data.allEvents.nodes || {}

  events = events.map(function (val) {
    let newVal = val
    // Date format must be "DD/MM/YYYY HH:MM PM" in order to be accepted by all browsers
    newVal.eventTimeStart = new Date(val.format_date_read + " " + formatTime(val.start_time))
    newVal.eventTimeEnd = new Date(val.format_date_read + " " + formatTime(val.end_time))
    return newVal
  })

  // Basically just the AgendaEvent component, but passing in an 'onClick' function
  const AgendaEventWithProps = ({ event }) => {
    return <AgendaEvent event={event} onSelectAgendaEvent={openEvent} />
  }

  let openEvent = function (event, e) {
    setSelectedEvent(event)
    setShow(true)
  }

  let handleClose = function () {
    setShow(false)
  }

  let components = { agenda: { event: AgendaEventWithProps } }

  return (
    <Layout contentTopMargin noBreadcrumb>
      {/* <Seo title="Calendar"> */}
      <Text.Header className="calendar-header" size="large">Calendar</Text.Header>
      <div className="mt-5">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="eventTimeStart"
          endAccessor="eventTimeEnd"
          titleAccessor="name"
          onSelectEvent={openEvent}
          components={components}
          style={{ height: 500 }}
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="px-3 py-2">
          <Modal.Title as="h3">{selectedEvent.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Event event={selectedEvent} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="button-close pt-2 px-3" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* </Seo> */}
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  query {
    allEvents {
      nodes {
        performers {
          name
        }
        start_time
        end_time
        date
        format_date_read: date(formatString: "M/D/Y")
        format_date: date(formatString: "MMMM D, YYYY")
        description
        live_stream_link
        video {
          video_url
          publish_date
          publish_time
        }
        name
      }
    }
  }
`
